<template>
  <div class="container">
    <div class="putitle">
      <span :class="
          datas.position == 1
            ? 'left'
            : datas.position == 3
            ? 'right'
            : ''
        ">{{ $i18n.locale == 'en' ? datas.titleen : datas.title }}</span>
    </div>
    <div class="recommend_swiper">
      <swiper navigation :slides-per-view="5" :free-mode="true" :initialSlide="0">
        <swiper-slide v-for="(item, i) in datas.item" :key="i">
          <div class="reitem">
            <div class="top" @mouseover="item.imgShow = true" @mouseleave="item.imgShow = false">
              <router-link :to="'/goods?guid=' + item.guid">
                <q-img :src="
                    item.imgShow
                      ? item.commoditypictures1.split(',')[1]
                      : item.commoditypictures1.split(',')[0]
                  " :ratio="6 / 8" />
              </router-link>
              <span v-show="item.iswish == 0" class="like iconfont icon-like" @click.stop="addWishClick(item)"></span>
              <span v-show="item.iswish == 1" class="like iconfont icon-like_fill" @click.stop="addWishClick(item)"></span>
              <span class="loupe iconfont icon-sousuo" @click.stop="loupeHandle(item)"></span>
            </div>
            <div class="retxt">
              <p class="blod no-wrap-txt">{{ item.generalattributename }}</p>
              <p class="no-wrap-txt">{{ $i18n.locale == "en" ? item.titleen : item.title }}</p>
              <p class="blod">￥{{ item.Price }}</p>
            </div>
            <!-- 做预加载图片 -->
            <div class="onload-img">
              <img :src="item.commoditypictures1.split(',')[1]" alt="" />
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import { api as viewerApi } from 'v-viewer'
import { addwish } from '@/api/goods'
import { Notify } from 'quasar'
import i18n from '../../../language'
import { swiperConfig } from '@/plugins/swiperConfig'
const { Swiper, SwiperSlide, Navigation, SwiperCore } = swiperConfig()
SwiperCore.use([Navigation])
export default {
  name: 'recommonShop',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    datas: {
      type: Object
    }
  },
  setup () {
    const $t = i18n.global.t
    const addWishClick = async (item) => {
      const res = await addwish({
        mark: item.guid
      })
      if (res.status === 1) {
        Notify.create($t('common.add_wish_success'))
        item.iswish = '1'
      } else {
        Notify.create($t('common.remove_wish_success'))
        item.iswish = '0'
      }
    }
    const loupeHandle = (item) => {
      viewerApi({
        images: item.commoditypictures1.split(',')
      })
    }
    return {
      addWishClick,
      loupeHandle
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin: 50px auto;
}

.recommend_swiper {
  max-width: 1500px;
  margin: 0 auto;
  --swiper-theme-color: #000;
  .reitem {
    cursor: pointer;
    .top {
      width: 100%;
      color: #000;
      overflow: hidden;
      position: relative;
      padding: 5px;
      box-sizing: border-box;
      -webkit-box-sizing: border-box;
      .like {
        position: absolute;
        right: 10px;
        top: 5px;
        font-size: 20px;
        cursor: pointer;
        z-index: 2;
      }
      .loupe {
        position: absolute;
        left: 10px;
        top: 5px;
        font-size: 20px;
        cursor: pointer;
        z-index: 2;
      }
    }
    .retxt {
      width: 100%;
      text-align: center;
      p {
        font-size: 14px;
        color: #000;
        margin-bottom: 0;
      }
      p:nth-of-type(1) {
        font-size: 16px;
      }
    }
    .onload-img {
      position: absolute;
      left: 0;
      top: 0;
      height: 0;
      visibility: hidden;
      img {
        width: 0;
        height: 0;
      }
    }
  }
}
</style>
